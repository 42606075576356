export const outlierRoutes = [
  {
    path: ':organizationId/:channelUcid/outliers',
    async lazy() {
      const { OutliersRoute } = await import('./outliers');
      return {
        Component: OutliersRoute,
      };
    },
  },
];
