import { Toast } from '@lib/ui';
import { AppProviders } from './providers';
import { Router } from './routes';

export function App() {
  const ToastProvider = Toast.ToastProvider;

  return (
    <AppProviders>
      <ToastProvider>
        <Router />
      </ToastProvider>
    </AppProviders>
  );
}
