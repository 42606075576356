import React, { ForwardedRef, forwardRef } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import { contentVariants, ContentVariants } from './tooltip.css';

/**
 * Tooltip Provider
 */
const Provider = Tooltip.TooltipProvider;

/**
 * Tooltip Root
 */
const Root = Tooltip.Root;

/**
 * Tooltip Trigger
 */
const Trigger = forwardRef(
  (
    props: Tooltip.TooltipTriggerProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Tooltip.Trigger ref={ref} {...props} className={clsx(props.className)} />
    );
  }
);
Trigger.displayName = 'TooltipTrigger';

/**
 * Tooltip Portal
 */
const Portal = Tooltip.Portal;

/**
 * Tooltip Content
 */
type ContentProps = Tooltip.TooltipContentProps &
  ContentVariants & {
    className?: string;
  };
const Content = forwardRef(
  (props: ContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { variant = 'dark', className } = props;
    return (
      <Tooltip.Content
        ref={ref}
        {...props}
        className={clsx(contentVariants({ variant }), className)}
      />
    );
  }
);
Content.displayName = 'TooltipContent';

/**
 * Tooltip Arrow
 */
const Arrow = Tooltip.Arrow;

export { Arrow, Content, Portal, Provider, Root, Trigger };
