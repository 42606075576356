import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  youtube_auth_error_page_empty_no_channels,
  youtube_auth_error_page_empty_no_channels_cancel_subscription_click,
  youtube_auth_error_page_empty_no_channels_connect_click,
} from '@studio/heap/events';
import { trackEvent } from '@studio/lib/heap';
import { Button, Flex, Heading, Text } from '@lib/ui';
import { AuthLayout } from '../../components';
import { useCreateStripeBillingSession } from '../../hooks/use-stripe-create-billing-session';
import { ConnectAChannelButton } from './connect-a-channel-button';
import * as SharedStyles from './shared.css';

export function NoChannels() {
  const { t } = useTranslation();
  const { createSession, session, isLoading } = useCreateStripeBillingSession();

  useEffect(() => {
    const getSession = async () => {
      try {
        await createSession();
      } catch (err) {
        console.error('Error creating session:', err);
      }
    };

    getSession();
  }, [createSession]);

  useEffect(() => {
    trackEvent(youtube_auth_error_page_empty_no_channels);
  }, []);

  const handleCancelSubscription = () => {
    if (session?.data.sessionUrl) {
      trackEvent(
        youtube_auth_error_page_empty_no_channels_cancel_subscription_click
      );
      window.location.href = session.data.sessionUrl;
    }
  };

  return (
    <AuthLayout>
      <Flex justifyContent="center">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          <Heading className={SharedStyles.title}>{t(`We're sorry`)}</Heading>
          <Trans t={t}>
            <Text className={SharedStyles.subText}>
              We couldn’t find a YouTube channel for this account.
            </Text>
            <Text className={SharedStyles.subText}>
              Please try another channel or cancel your subscription.
            </Text>
          </Trans>
          <Flex className={SharedStyles.actions} paddingTop="2rem" gap="16px">
            <Button
              className={SharedStyles.button}
              pill
              fill="ghost"
              size="lg"
              onClick={handleCancelSubscription}
              disabled={isLoading || !session?.data.sessionUrl}
            >
              <Trans t={t}>
                <Text>Cancel subscription</Text>
              </Trans>
            </Button>
            <ConnectAChannelButton
              copy="Connect a channel"
              trackEventType={
                youtube_auth_error_page_empty_no_channels_connect_click
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </AuthLayout>
  );
}
