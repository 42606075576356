import { type ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StatsigProvider, StatsigUser } from 'statsig-react';
import { useAuthStore } from '@studio/features/auth';
import { useAutoTokenRefresh } from '@studio/hooks/use-auto-token-refresh';
import Intercom from '@studio/lib/intercom/intercom';
import { getEnvironment } from '@studio/utils/env-utils';
import HeapAnalytics from '../lib/heap/analytics';

export const queryClient = new QueryClient();

export function AppProviders({ children }: { children: ReactNode }) {
  const { email, id, isInternalUser } = useAuthStore((store) => ({
    email: store.user?.email,
    id: store.user?.id,
    isInternalUser: store.isInternalUser(),
  }));

  const statsigUser: StatsigUser = {
    email,
    userID: id,
    custom: {
      isInternal: isInternalUser,
    },
  };

  useAutoTokenRefresh();

  return (
    <QueryClientProvider client={queryClient}>
      <StatsigProvider
        sdkKey={import.meta.env.VITE_STATSIG_STUDIO_CLIENT_KEY}
        user={statsigUser}
        options={{
          environment: {
            tier: getEnvironment(),
          },
        }}
      >
        {children}
        <Intercom />
        <HeapAnalytics />
      </StatsigProvider>
    </QueryClientProvider>
  );
}
