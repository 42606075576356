export const DEFAULT_PAGE_SIZE = '24';
export const DEFAULT_PERFORMANCE_INDEX_GTE = '1.1';
export const DEFAULT_MADE_FOR_KIDS = 'false';
export const DISPLAY_FORMAT = 'M/dd/yyyy';
export const MIN_LABEL = 'Min';
export const MAX_LABEL = 'Max';

export const AUTO_COMPLETE_MODE = {
  MIXED: 'mixed',
  VIDEO: 'video',
  CHANNEL: 'channel',
};

export const AUTO_COMPLETE_PARAMS = {
  SEARCH_QUERY: 'search_query',
  SEARCH_TERM: 'search_term',
  PAGE: 'page',
  COUNT: 'count',
};

export const SOURCE = {
  ALL: 'all',
  AAW: 'aaw',
  MYVIDS: 'myvids',
  FAVORITECHANNELS: 'favorite_channels',
} as const;

export const SOURCE_ITEMS = [
  {
    label: 'All of YouTube',
    value: SOURCE.ALL,
  },
  {
    label: 'My audience also watched',
    value: SOURCE.AAW,
  },
  {
    label: 'Following',
    value: SOURCE.FAVORITECHANNELS,
  },
  {
    label: 'My videos',
    value: SOURCE.MYVIDS,
  },
];

export const SEARCH_BY = {
  MIXED: 'mixed',
  VIDEO: 'video',
  CHANNEL: 'channel',
} as const;

export const SEARCH_BY_ITEMS = [
  {
    label: 'Search',
    value: SEARCH_BY.MIXED,
  },
  {
    label: 'Video',
    value: SEARCH_BY.VIDEO,
  },
  {
    label: 'Channel',
    value: SEARCH_BY.CHANNEL,
  },
];

export const LAYOUT = {
  GRID: 'grid',
  TABLE: 'table',
} as const;

export const FILTER_CHIPS = {
  SOURCE: 'source',
  UPLOAD_DATE: 'uploadDate',
  SUBSCRIBERS: 'subscribers',
  OUTLIER_INDEX: 'outlierIndex',
  V30: 'v30',
  VIEWS: 'view',
  DURATION: 'duration',
  MADE_FOR_KIDS: 'madeForKids',
  SEARCH: 'search',
} as const;

export const SORT_ORDER = {
  PERFORMANCE_INDEX_ASC: 'performance_index_asc',
  PERFORMANCE_INDEX_DESC: 'performance_index_desc',
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  TITLE1_ASC: 'title1_asc',
  TITLE1_DESC: 'title1_desc',
  UPLOADED_ASC: 'uploaded_asc',
  UPLOADED_DESC: 'uploaded_desc',
  VIEWS_ASC: 'views_asc',
  VIEWS_DESC: 'views_desc',
  SUB_ASC: 'subscribers_asc',
  SUB_DESC: 'subscribers_desc',
  V7_VIEWS_ASC: 'v7_score_asc',
  V7_VIEWS_DESC: 'v7_score_desc',
  CATEGORY_ASC: 'category_asc',
  CATEGORY_DESC: 'category_desc',
} as const;

export const OUTLIER_FILTER = {
  SOURCE_OPEN: 'outlier_filter_source_open',
  INDEX_OPEN: 'outlier_filter_index_open',
  UPLOADED_OPEN: 'outlier_filter_uploaded_open',
  SUBS_OPEN: 'outlier_filter_subs_open',
  MORE_OPEN: 'outlier_filter_more_open',
  SOURCE_CHANGE: 'outlier_filter_source_change',
  INDEX_CHANGE: 'outlier_filter_index_change',
  UPLOADED_CHANGE: 'outlier_filter_uploaded_change',
  SUBS_CHANGE: 'outlier_filter_subs_change',
  VIDEO_VIEWS_CHANGE: 'outlier_filter_videoviews_change',
  VIDEO_DURATION_CHANGE: 'outlier_filter_videoduration_change',
  MADE_FOR_KIDS_CHANGE: 'outlier_filter_made4kids_change',
  SEARCH_CHANGE: 'outlier_filter_search_change',
  RESET: 'outlier_filter_reset',
} as const;

export const OUTLIER_LIST = {
  CHANNEL_HEADER: 'outlier_list_channelheader_sort_click',
  SUB_HEADER: 'outlier_list_subsheader_sort_click',
  VIDEO_HEADER: 'outlier_list_videoheader_sort_click',
  OUTLIER_INDEX_HEADER: 'outlier_list_outlierindexheader_sort_click',
  UPLOAD_HEADER: 'outlier_list_uploadheader_sort_click',
  V7_VIEWS_HEADER: 'outlier_list_v7viewsheader_sort_click',
  VIEWS_HEADER: 'outlier_list_viewsheader_sort_click',
  CATEGORY_HEADER: 'outlier_list_categoryheader_sort_click',
} as const;

export const OUTLIER_ACTIONS = {
  ADD_VIDEO_TO_FAVOURITE: 'outlier_add_video_to_favourite_click',
  ADD_CHANNEL_TO_FAVOURITE: 'outlier_add_channel_to_favourite_click',
  REMOVE_VIDEO_FROM_FAVOURITE: 'outlier_remove_video_to_favourite_click',
  REMOVE_CHANNEL_FROM_FAVOURITE: 'outlier_remove_channel_to_favourite_click',
  VIDEO_LINK_TO_YOUTUBE: 'outlier_video_link_to_youtube_click',
  CHANNEL_LINK_TO_YOUTUBE: 'outlier_channel_link_to_youtube_click',
} as const;

export const NAVIGATION = {
  FAVOURITE_BOARD_OPEN: 'favourite_board_open',
  NEW_PROJECT_FROM_HOME_PAGE_CLICK: 'new_project__from_home_page_click',
  USER_PANEL_CLICK: 'user_panel_click',
  HOME_CLICK: 'home_click',
  OUTLIER_CLICK: 'outlier_click',
  GRIDSORT_CLICK: 'outlier_gridsort_click',
  OUTLIER_LAYOUT_TOGGLE: 'outlier_layout_toggle',
} as const;

export const OUTLIER_GRID = {
  CHANNEL: 'outlier_gridsort_channel_sort_click',
  SUB: 'outlier_gridsort_subs_sort_click',
  VIDEO: 'outlier_gridsort_video_sort_click',
  OUTLIER_INDEX: 'outlier_gridsort_outlierindex_sort_click',
  UPLOAD: 'outlier_gridsort_upload_sort_click',
  V7_VIEWS: 'outlier_gridsort_v7views_sort_click',
  VIEWS: 'outlier_gridsort_views_sort_click',
  CATEGORY: 'outlier_gridsort_category_sort_click',
} as const;

export const DISPLAY_PARAMS = {
  COUNT: 'count',
  LAYOUT: 'layout',
  PAGE: 'page',
  SORT_BY: 'sortBy',
  SOURCE: 'source',
} as const;

export const FILTER_PARAMS = {
  DURATION_GTE: 'durationGte',
  DURATION_LTE: 'durationLte',
  PERFORMANCE_INDEX_GTE: 'performanceIndexGte',
  PERFORMANCE_INDEX_LTE: 'performanceIndexLte',
  SUBSCRIBERS_GTE: 'subscribersGte',
  SUBSCRIBERS_LTE: 'subscribersLte',
  UPLOADED_END: 'uploadedEnd',
  UPLOADED_START: 'uploadedStart',
  V30_GTE: 'v30Gte',
  V30_LTE: 'v30Lte',
  VIEWS_GTE: 'viewsGte',
  VIEWS_LTE: 'viewsLte',
  MADE_FOR_KIDS: 'madeForKids',
  SEARCH: 'search',
  SEARCH_BY: 'searchBy',
  COUNT: 'count',
  LAYOUT: 'layout',
  PAGE: 'page',
  SORT_BY: 'sortBy',
  SOURCE: 'source',
  NAME: 'name',
  UCID: 'ucid',
} as const;

export const PARAM_KEYS = {
  ...DISPLAY_PARAMS,
  ...FILTER_PARAMS,
};

export interface DefaultValues {
  [key: string]: string;
}

export const DEFAULT_FILTER_VALUES: DefaultValues = {
  [PARAM_KEYS.DURATION_GTE]: '',
  [PARAM_KEYS.DURATION_LTE]: '',
  [PARAM_KEYS.NAME]: '',
  [PARAM_KEYS.MADE_FOR_KIDS]: DEFAULT_MADE_FOR_KIDS,
  [PARAM_KEYS.PERFORMANCE_INDEX_GTE]: DEFAULT_PERFORMANCE_INDEX_GTE,
  [PARAM_KEYS.PERFORMANCE_INDEX_LTE]: '',
  [PARAM_KEYS.SOURCE]: SOURCE.ALL,
  [PARAM_KEYS.SUBSCRIBERS_GTE]: '',
  [PARAM_KEYS.SUBSCRIBERS_LTE]: '',
  [PARAM_KEYS.UCID]: '',
  [PARAM_KEYS.UPLOADED_END]: '',
  [PARAM_KEYS.UPLOADED_START]: '',
  [PARAM_KEYS.VIEWS_GTE]: '',
  [PARAM_KEYS.VIEWS_LTE]: '',
} as const;

export const DEFAULT_VALUES: DefaultValues = {
  ...DEFAULT_FILTER_VALUES,
  [PARAM_KEYS.SORT_BY]: SORT_ORDER.UPLOADED_DESC,
  [PARAM_KEYS.COUNT]: DEFAULT_PAGE_SIZE,
  [PARAM_KEYS.LAYOUT]: LAYOUT.GRID,
} as const;
