import './sentry';
import './globals.css';
import './i18n';

import { StrictMode } from 'react';
import { inject } from '@vercel/analytics';
import { SpeedInsights } from '@vercel/speed-insights/react';
import * as ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { App } from './app';
import { ErrorBoundaryScreen } from './components/error-screens';
import { AppProviders } from './providers';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Initializes vercel analytics
inject();

root.render(
  <StrictMode>
    <ErrorBoundary fallback={<ErrorBoundaryScreen />}>
      <AppProviders>
        <App />
      </AppProviders>
      <SpeedInsights />
    </ErrorBoundary>
  </StrictMode>
);
