import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useParamStore } from '@studio/stores';
import { DEFAULT_VALUES } from '../constants';
import { ProjectsParams } from '../types';

type FilterVisibilityStore = {
  visible: boolean;
  toggleVisibility: () => void;
};

export const useFilterVisibilityStore = create<FilterVisibilityStore>()(
  devtools((set, get) => ({
    visible: false,
    toggleVisibility: () => set({ visible: !get().visible }),
  }))
);

const displayProperties = ['count', 'layout', 'sort_by', 'scope'];

type DefaultKey = keyof typeof DEFAULT_VALUES;

function filtersApplied(
  filterParams: Partial<Record<ProjectsParams, string>>,
  propertiesToExclude: string[]
): boolean {
  return Object.keys(filterParams)
    .filter((key) => !propertiesToExclude.includes(key))
    .reduce((applied, key) => {
      const hasDefaultValue = DEFAULT_VALUES[key as DefaultKey] !== undefined;
      const isDifferentFromDefaultValue =
        filterParams[key as DefaultKey] !== DEFAULT_VALUES[key as DefaultKey];
      return applied || (hasDefaultValue && isDifferentFromDefaultValue);
    }, false);
}

type FilterStore = {
  hasFiltersApplied: () => boolean;
};

export const useFilterStore = create<FilterStore>()(
  devtools(
    () => ({
      hasFiltersApplied: () => {
        const params = useParamStore.getState().params;
        return filtersApplied(params, displayProperties);
      },
    }),
    { name: 'projects-filters' }
  )
);
