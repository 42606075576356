import { useState } from 'react';
import { Plan } from '@studio/features/projects/requests';
import { api } from '@studio/lib';
import { type SessionData } from '@studio/types';
import { PlanType } from '../routes/routes';
import { usePartnerStackParams } from './use-partner-stack-params';

type CreateStripeCheckoutSessionInput = {
  planType: PlanType;
  plan: Plan;
  customerId: string;
};

export const useCreateStripeCheckoutSession = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [session, setSession] = useState<SessionData | null>(null);

  const marketingParams = usePartnerStackParams();
  const createSession = async ({
    planType,
    customerId,
    plan,
  }: CreateStripeCheckoutSessionInput) => {
    try {
      let price =
        planType === PlanType.Yearly
          ? plan.price.annualStripeId
          : plan.price.monthlyStripeId;

      if (marketingParams.plan.length) {
        price =
          planType === PlanType.Yearly
            ? `${marketingParams.plan}-annual`
            : `${marketingParams.plan}-monthly`;
      }

      const checkoutSession = await api.boo.post<SessionData>(
        `/api/stripe/create-checkout-session`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            price,
            customerId,
            returnUrl: `${window.location.origin}/auth/connect-channel`,
            promoCode: plan.allowCoupon,
            collectionMode: plan.collectionMode,
            metadata: marketingParams,
            ...(plan.trial?.active && {
              trialDays: plan.trial.period,
              trialEndBehavior: plan.trial.endBehavior,
            }),
          },
        }
      );

      setSession(checkoutSession);
      return checkoutSession;
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'An unexpected error occurred'
      );
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { createSession, error, isLoading, session };
};
