import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type TotalVideoCount = {
  count?: number;
  setCount: (count?: number) => void;
};

export const useVideoTotalCountStore = create<TotalVideoCount>()(
  devtools(
    (set) => ({
      count: undefined,
      setCount: (count) =>
        set(() => ({ count }), false, 'video-total-count/set-count'),
    }),
    { name: 'video-total-count' }
  )
);
