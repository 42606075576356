import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function ArrowRightIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3938 24.5061C17.7518 25.1393 17.7469 26.1707 18.383 26.8099C19.0191 27.449 20.0551 27.4538 20.6971 26.8206L30.5153 17.1368C30.8255 16.8309 31 16.4142 31 15.9795C31 15.5449 30.8255 15.1282 30.5153 14.8223L20.6971 5.13848C20.0551 4.50526 19.0191 4.51006 18.383 5.14919C17.7469 5.78833 17.7518 6.81978 18.3938 7.453L25.4787 14.441L2.63636 14.441C1.73262 14.441 1 15.1703 1 16.07C1 16.9697 1.73262 17.6991 2.63636 17.6991L25.2952 17.6991L18.3938 24.5061Z"
        fill="currentColor"
      />
    </svg>
  );
}
