import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type NewProjectIdStore = {
  id: number;
  getId: () => void;
};

export const useNewProjectId = create<NewProjectIdStore>()(
  devtools(
    (set, get) => ({
      id: 0,
      getId: () => {
        const id = get().id + 1;
        set(() => ({ id: id }), false, 'new-project-id/get-id');
        return id;
      },
    }),
    { name: 'new-project-id' }
  )
);
