import { PROJECT_STATUS } from '@lib/types';

export const DEFAULT_PAGE_SIZE = '23';
export const DISPLAY_FORMAT = 'M/dd/yyyy';
export const DEFAULT_PROJECT_CONCEPT_MAX_SIZE = 750;

export const ACCESS = {
  USER_OWNED: 'owner',
  SHARED: 'shared',
  CHANNEL: 'channel',
  ALL: 'all',
} as const;

export const LAYOUT = {
  GRID: 'grid',
  TABLE: 'table',
} as const;

export const STATUS = {
  ALL: 'all',
  ...PROJECT_STATUS,
} as const;

export const FILTER_CHIPS = {
  ACCESS: 'access',
  DATE_ADDED: 'date_added',
  PUBLISHED_DATE: 'published_date',
  SPONSOR: 'sponsor',
  THUMBNAIL: 'thumbnail',
  TITLE: 'title',
  CONCEPT: 'concept',
} as const;

export const SORT_ORDER = {
  EDITED_ASC: '-updatedAt',
  EDITED_DESC: 'updatedAt',
  PUBLISHED_ASC: '-publishDate',
  PUBLISHED_DESC: 'publishDate',
  CREATED_ASC: '-createdAt',
  CREATED_DESC: 'createdAt',
  VIEWED_ASC: 'viewed_asc',
  VIEWED_DESC: 'viewed_desc',
  NAME_ASC: 'name',
  NAME_DESC: '-name',
} as const;

export const PARAM_KEYS = {
  COUNT: 'count',
  LAYOUT: 'layout',
  PAGE: 'page',
  SORT_BY: 'sort_by',
  RELEASED_START: 'released_start',
  RELEASED_END: 'released_end',
  CREATED_START: 'created_start',
  CREATED_END: 'created_end',
  ACCESS: 'access',
  STATUS_IN: 'status__in',
  THUMBNAIL_EXISTS: 'thumbnail__exists',
  TITLE_EXISTS: 'title__exists',
  CONCENT_EXISTS: 'concept__exists',
  SPONSORS_EXISTS: 'sponsors__exists',
  SEARCH: 'search',
} as const;

export const DEFAULT_VALUES = {
  [PARAM_KEYS.COUNT]: DEFAULT_PAGE_SIZE,
  [PARAM_KEYS.LAYOUT]: LAYOUT.GRID,
  [PARAM_KEYS.ACCESS]: ACCESS.ALL,
  [PARAM_KEYS.SORT_BY]: SORT_ORDER.EDITED_ASC,
} as const;

export const DELETE_PROJECT_MODAL = 'delete-project-modal';
export const DELETE_THUMBNAIL_MODAL = 'delete-thumbnail-modal';

export const PANEL_CONTROL_PARAM = 'panel';
export const PANEL_CONTROL_BACKUPS_PREFIX = 'backups';
export const PANEL_CONTROL_BACKUPS_THUMB = 'backups-thumbnail';
export const PANEL_CONTROL_BACKUPS_TITLE = 'backups-title';
export const PANEL_CONTROL_BACKUPS_CONCEPT = 'backups-concept';
export const PANEL_CONTROL_AI_SETTINGS = 'ai';

export const PANEL_VISIBILITY_PARAM = 'expanded';
