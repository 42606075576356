import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { JsonObject, LsonObject, BaseUserMeta, Json } from '@liveblocks/client';
import LiveblocksProvider from '@liveblocks/yjs';
import * as Y from 'yjs';
import { useAuthStore } from '@studio/features/auth';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useActiveOrganizationId } from '@studio/stores';
import { Flex, Spinner } from '@lib/ui';
import { debounce } from '@lib/utils';
import { createVersion } from '@lib/yjs-utils';
import { ProjectStoreProvider } from './project-store-provider';
import { SetRoomProvider } from './room-provider';

type LBProvider = LiveblocksProvider<
  JsonObject,
  LsonObject,
  BaseUserMeta,
  Json
>;

export const YDocStoreContext = createContext<{
  doc: Y.Doc;
  yUserData: Y.PermanentUserData;
  provider: LBProvider | null | undefined;
  saving: boolean;
}>({
  doc: new Y.Doc(),
  yUserData: new Y.PermanentUserData(new Y.Doc()),
  provider: null,
  saving: false,
});

export function YDocProvider({ children }: { children: ReactNode }) {
  const activeChannelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const { user } = useAuthStore();
  const { room } = useContext(SetRoomProvider);
  const [doc] = useState<Y.Doc>(new Y.Doc());
  const location = useLocation();
  const [yUserData, setYUserData] = useState<Y.PermanentUserData>();
  const [provider, setProvider] = useState<LBProvider>();
  const [saving, setSaving] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(
    location.pathname.endsWith('new-project')
  );

  const toggleSaving = useCallback(() => {
    setSaving(true);
    setTimeout(() => {
      setSaving(false);
    }, 3000);
  }, [setSaving]);

  useEffect(() => {
    // map the instance of the ydoc to the user
    if (user) {
      const permanentUserData = new Y.PermanentUserData(doc);
      permanentUserData.setUserMapping(doc, doc.clientID, user?.email);
      setYUserData(permanentUserData);
    }

    // listen for updates to create versions
    const debouncedCreateVersion = debounce((doc, author) => {
      toggleSaving();
      createVersion(doc, {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        imgUrl: user?.profilePicture,
      });
    }, 1000);

    const onUpdate = (transaction: Y.Transaction, currentDoc: Y.Doc) => {
      // This origins come from the two plugins we use, tip-tap collaboration and zustand-middleware-yjs
      if (
        transaction.origin === 'zustand_middleware' ||
        (transaction.origin as { key: string })?.key === 'y-sync$'
      ) {
        debouncedCreateVersion(doc, user);
      }
    };
    doc.on('afterTransaction', onUpdate);

    return () => {
      doc?.off('afterTransaction', onUpdate);
      doc?.destroy();
    };
  }, [user, activeChannelUcid, orgId]);

  // Set up Liveblocks Yjs provider
  useEffect(() => {
    if (room && doc) {
      const yProvider = new LiveblocksProvider(room, doc);
      setProvider(yProvider || null);
      // if it's not a new project delay showing the project until we've synced with liveblocks
      if (!location.pathname.endsWith('new-project')) {
        yProvider.once('sync', (sync: boolean) => {
          if (sync) {
            setHasLoaded(sync);
          }
        });
      }
      return () => yProvider.destroy();
    }
  }, [room, doc, location]);

  if (!doc || !yUserData || !hasLoaded) {
    return (
      <Flex
        minHeight="calc(100vh - 96px)"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="12px"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <YDocStoreContext.Provider value={{ doc, yUserData, provider, saving }}>
      <ProjectStoreProvider doc={doc}>{children}</ProjectStoreProvider>
    </YDocStoreContext.Provider>
  );
}
