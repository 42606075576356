import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function UploadIcon(props: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, props.className)}
    >
      <path
        d="M4.75 19.25C4.75 18.5596 4.19036 18 3.5 18C2.80964 18 2.25 18.5596 2.25 19.25H4.75ZM25.75 19.25C25.75 18.5596 25.1904 18 24.5 18C23.8096 18 23.25 18.5596 23.25 19.25H25.75ZM7.86612 7.86612C7.37796 8.35427 7.37796 9.14573 7.86612 9.63388C8.35427 10.122 9.14573 10.122 9.63388 9.63388L7.86612 7.86612ZM14 3.5L14.8839 2.61612C14.3957 2.12796 13.6043 2.12796 13.1161 2.61612L14 3.5ZM18.3661 9.63388C18.8543 10.122 19.6457 10.122 20.1339 9.63388C20.622 9.14573 20.622 8.35427 20.1339 7.86612L18.3661 9.63388ZM12.75 19.25C12.75 19.9404 13.3096 20.5 14 20.5C14.6904 20.5 15.25 19.9404 15.25 19.25H12.75ZM21.875 23.25H6.125V25.75H21.875V23.25ZM4.75 21.875V19.25H2.25V21.875H4.75ZM23.25 19.25V21.875H25.75V19.25H23.25ZM6.125 23.25C5.36561 23.25 4.75 22.6344 4.75 21.875H2.25C2.25 24.0151 3.9849 25.75 6.125 25.75V23.25ZM21.875 25.75C24.0151 25.75 25.75 24.0151 25.75 21.875H23.25C23.25 22.6344 22.6344 23.25 21.875 23.25V25.75ZM9.63388 9.63388L14.8839 4.38388L13.1161 2.61612L7.86612 7.86612L9.63388 9.63388ZM13.1161 4.38388L18.3661 9.63388L20.1339 7.86612L14.8839 2.61612L13.1161 4.38388ZM15.25 19.25L15.25 3.5L12.75 3.5L12.75 19.25H15.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
