type Fetch = typeof fetch;

export const fetchWithRetry: Fetch = async (...args) => {
  const fetched = await fetch(...args);

  if (fetched.status === 401) {
    try {
      // Attempt to refresh token and get new cookies.
      const bowserUrl = `${import.meta.env.VITE_BOWSER_URL}/api/auth0/refresh`;
      const attempt = await fetch(bowserUrl, {
        method: 'POST',
        credentials: 'include',
      });

      if (attempt.status === 401) {
        throw new Error('Unauthorized');
      }

      const retry = await fetch(...args);
      return retry;
    } catch (error) {
      window.location.href = `${window.location.origin}/auth/logout`;
    }
  }

  return fetched;
};
