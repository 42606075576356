import { useQuery } from '@tanstack/react-query';
import { api } from '@studio/lib';
import { UserProfile } from '@studio/types';

export function useProfileQuery() {
  return useQuery({
    queryKey: ['user-profile'],
    queryFn: async () => api.bowser.get<UserProfile>('/api/users/profile'),
  });
}
