import {
  AllHTMLAttributes,
  CSSProperties,
  ElementType,
  ReactNode,
} from 'react';
import clsx from 'clsx';
import * as Styles from './text.css';

export type TextProps = Omit<
  AllHTMLAttributes<HTMLElement>,
  'as' | 'width' | 'height' | 'color' | 'className' | 'size' | 'weight'
> & {
  align?: 'left' | 'center' | 'right';
  as?: ElementType;
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
  display?: 'inline' | 'flex' | 'inline-flex' | 'block';
  lineHeight?: string;
  textDecoration?: string;
  fontStyle?: string;
  textTransform?: string;
} & Styles.TextVariants;

export const Text = (props: TextProps) => {
  const {
    align,
    children,
    className,
    color,
    clipping = 'none',
    size = '13',
    weight = 'medium',
    display = 'inline',
    lineHeight = '',
    textDecoration = 'normal',
    fontStyle = 'normal',
    textTransform = 'none',
    as: Tag = 'span',
    style: baseStyle,
    ...rest
  } = props;

  const dynamicStyles = {
    ...(lineHeight && { lineHeight }),
    ...(textDecoration && { textDecoration }),
    ...(fontStyle && { fontStyle }),
    ...(textTransform && { textTransform }),
  };

  return (
    <Tag
      style={{ ...baseStyle, ...dynamicStyles }}
      className={clsx(
        Styles.textVariants({
          align,
          color,
          size,
          weight,
          clipping,
          display,
        }),
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};
