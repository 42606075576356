import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function KeyboardArrowRightIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      className={clsx(iconStyles, props.className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.26326 8L2.7544 1.49113L4.24553 0L12.2455 8L4.24553 16L2.7544 14.5089L9.26326 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
