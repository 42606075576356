import { GraphQLClient } from 'graphql-request';

const client = new GraphQLClient(import.meta.env.VITE_GRAPHQL_URL, {
  credentials: 'include',
  responseMiddleware: async (_response) => {
    // The types for `responseMiddleware` are incorrect but the ticket was closed
    // https://github.com/jasonkuhrt/graphql-request/issues/542
    const { response } = _response as unknown as { response: typeof _response };
    // These types are probably not correct but it shuts up the compiler.
    const { request } = _response as unknown as { request: typeof _response };

    if (!(response instanceof Error) && response?.status === 401) {
      try {
        // Attempt to refresh token and get new cookies.
        // prettier-ignore
        const bowserUrl = `${import.meta.env.VITE_BOWSER_URL}/api/auth0/refresh`;
        const attempt = await fetch(bowserUrl, {
          method: 'POST',
          credentials: 'include',
        });

        if (attempt.status === 401) {
          throw new Error('Unauthorized');
        }

        return request;
      } catch (error) {
        // TODO Sentry event here.
        window.location.href = `${window.location.origin}/auth/logout`;
      }
    }
    return response;
  },
});

export default client;
