import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function CloseIcon(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, props.className)}
    >
      <path
        d="M11.9915 13.8157L6.18007 19.6272C5.92021 19.887 5.62491 20.011 5.29417 19.9992C4.96344 19.9874 4.66814 19.8516 4.40828 19.5917C4.14842 19.3319 4.01849 19.0307 4.01849 18.6881C4.01849 18.3456 4.14842 18.0444 4.40828 17.7845L10.1843 12.0085L4.37285 6.19702C4.11298 5.93715 3.98896 5.63595 4.00077 5.2934C4.01258 4.95086 4.14842 4.64965 4.40828 4.38979C4.66814 4.12993 4.96935 4 5.31189 4C5.65444 4 5.95564 4.12993 6.2155 4.38979L11.9915 10.2013L17.803 4.38979C18.0628 4.12993 18.364 4 18.7066 4C19.0491 4 19.3503 4.12993 19.6102 4.38979C19.8701 4.64965 20 4.95086 20 5.2934C20 5.63595 19.8701 5.93715 19.6102 6.19702L13.7987 12.0085L19.6102 17.8199C19.8701 18.0798 20 18.3751 20 18.7058C20 19.0366 19.8701 19.3319 19.6102 19.5917C19.3503 19.8516 19.0491 19.9815 18.7066 19.9815C18.364 19.9815 18.0628 19.8516 17.803 19.5917L11.9915 13.8157Z"
        fill="currentColor"
      />
    </svg>
  );
}
