import { create } from 'zustand';

export type ModalStore = {
  modals: Record<string, boolean>;
  closeModal: (id: string) => void;
  openModal: (id: string) => void;
  toggleModal: (id: string) => void;
};

export const useModalStore = create<ModalStore>((set, get) => ({
  modals: {},
  closeModal: (id) => {
    set({
      modals: {
        ...get().modals,
        [id]: false,
      },
    });
  },
  openModal: (id) => {
    set({
      modals: {
        ...get().modals,
        [id]: true,
      },
    });
  },
  toggleModal: (id) => {
    const modals = get().modals;
    set({
      modals: {
        ...get().modals,
        [id]: modals[id] ? false : true,
      },
    });
  },
}));
