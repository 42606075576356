import { CSSProperties, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useIsOnScreen } from '../hooks';
import * as Styles from './image.css';

export type ImageProps = {
  /**
   * URL of the image to be loaded
   */
  src?: string;

  /**
   * alt text for the image
   */
  alt?: string;

  /**
   * Aspect ratio, defaults to `1` (square)
   * @example "16/9"
   */
  aspectRatio?: string;

  /**
   * Additional CSS classes for the image
   */
  className?: string;

  /**
   * Determines if the Image component should fade in once mounted
   */
  fadeIn?: boolean;

  /**
   * URL of a placeholder image. This image will be loaded and rendered in before the src
   */
  placeholderSrc?: string;

  /**
   * Inline styles
   */
  style?: CSSProperties;
};

export const Image = (props: ImageProps) => {
  const {
    alt = '',
    aspectRatio = '1',
    className,
    fadeIn = false,
    placeholderSrc = '',
    src = '',
    style,
  } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);

  useIsOnScreen(imageRef, () => setIsLoading(false));

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const inlineStyles: CSSProperties = {
    aspectRatio,
    height: '100%',
  };

  if (fadeIn) {
    inlineStyles.opacity = isMounted ? '1' : '0';
  }

  return (
    <img
      ref={imageRef}
      src={isLoading ? placeholderSrc : src}
      alt={alt}
      className={clsx(Styles.image, className)}
      style={{
        filter: isLoading ? 'blur(10px)' : 'blur(0px)',
        ...inlineStyles,
        ...style,
      }}
    />
  );
};
