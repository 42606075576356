import {
  forwardRef,
  ElementType,
  ForwardedRef,
  type ButtonHTMLAttributes,
  type ReactNode,
} from 'react';
import clsx from 'clsx';
import * as Styles from './button.css';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Styles.ButtonVariants & {
    href?: string;
    as?: ElementType;
    children: ReactNode;
    loading?: boolean;
    type?: 'button' | 'submit' | 'reset';
  };

export const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      as: Component = 'button',
      type = 'button',
      size = 'md',
      variant = 'primary',
      fill = 'solid',
      pill = false,
      className,
      loading,
      ...rest
    } = props;

    const classes = clsx(
      Styles.buttonVariants({ size, variant, fill, pill }),
      loading && 'loading',
      className
    );

    return (
      <Component ref={ref} type={type} className={classes} {...rest}>
        {props.children}
      </Component>
    );
  }
);

Button.displayName = 'Button';
