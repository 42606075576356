import { api } from '@studio/lib';

export type PlanPrice = {
  annual: number;
  monthly: number;
  annualStripeId: string;
  monthlyStripeId: string;
};

export type PlanDiscount = {
  percentage: number;
  period: number;
  description: string;
} | null;

export type PlanTrial = {
  active: boolean;
  period: number;
  endBehavior: 'cancel' | 'continue';
};

export type Plan = {
  price: PlanPrice;
  allowCoupon: boolean;
  _id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string | null;
  deletedBy: string | null;
  name: string;
  sku: string;
  description: string;
  discount: PlanDiscount;
  trial: PlanTrial;
  cost: number;
  active: boolean;
  maxSeatCount: number | null;
  maxChannelCount: number | null;
  __v: number;
  collectionMode: string;
};

type ApiResponse = {
  status: number;
  message: string;
  data: Plan[];
};

export const fetchStripePlans = async (): Promise<Plan[]> => {
  try {
    const response = await api.boo.get<ApiResponse>('/api/plans');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
