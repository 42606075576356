import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import * as Toggle from '@radix-ui/react-toggle';
import clsx from 'clsx';
import * as Icons from '../icons';
import { Mode } from './enum';
import * as Styles from './toggle-button.css';

export type ToggleButtonProps = Toggle.ToggleProps & {
  label?: string;
  value?: boolean;
};

type Props = ToggleButtonProps & Styles.ToggleButtonVariants;

export const ToggleButton = forwardRef(
  (
    {
      className,
      fill = 'solid',
      variant = 'light',
      label,
      labelPosition = 'left',
      mode = Mode.Select,
      pill = false,
      ...props
    }: Props,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { t } = useTranslation();

    const activated = props.pressed || false;

    const defaultLabel = mode === Mode.Select ? t('Select') : t('Save');
    const labelText = label || defaultLabel;

    const circleCheckboxStyle = clsx(Styles.circleCheckbox, {
      [Styles.whiteColor]: fill === 'solid' && variant !== 'subtle',
      [Styles.blackColor]: fill === 'ghost' || variant === 'subtle',
    });

    const circleIconStyle = clsx(Styles.circleIcon, {
      [Styles.whiteColor]: fill === 'ghost' || variant === 'subtle',
      [Styles.blackColor]: fill === 'solid' && variant !== 'subtle',
    });

    const heartCheckboxStyle = clsx(Styles.heartCheckboxStyles, {
      [Styles.whiteStrokeHeart]:
        !activated && (fill === 'ghost' || variant === 'subtle'),
      [Styles.blackStrokeHeart]:
        !activated && fill === 'solid' && variant !== 'subtle',
      [Styles.redFilledheartIcon]: activated,
    });

    return (
      <Toggle.Root
        ref={ref}
        className={clsx(
          Styles.toggleButtonVariants({ fill, variant, labelPosition, pill }),
          className
        )}
        aria-label={mode}
        {...props}
      >
        <div
          className={
            labelPosition === 'left'
              ? Styles.leftLabelIconStyles
              : Styles.topLabelPositionStyles
          }
        >
          {labelPosition !== 'none' && <label>{labelText}</label>}
          {mode === Mode.Select ? (
            <div className={circleCheckboxStyle}>
              {activated && <div className={circleIconStyle} />}
            </div>
          ) : (
            <div className={heartCheckboxStyle}>
              {activated ? <Icons.HeartFilledIcon /> : <Icons.HeartIcon />}
            </div>
          )}
        </div>
      </Toggle.Root>
    );
  }
);
