import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isAfter, isBefore } from 'date-fns';
import { Flex, Separator } from '@lib/ui';
import { FilterDateInput } from '../inputs';
import { ErrorText } from './error-text';
import { panelInputs } from './filters.css';

type DateFilterProps = {
  maxId: string;
  maxLabel: string;
  minId: string;
  minLabel: string;
  displayFormat: string;
  params: Record<string, string>;
  removeParam: (paramId: string) => void;
  setParams: (value: { [key: string]: string }) => void;
};

export const DateFilter = (props: DateFilterProps) => {
  const {
    minId,
    minLabel,
    maxId,
    maxLabel,
    displayFormat,
    params,
    removeParam,
    setParams,
  } = props;

  const { t } = useTranslation();
  const initialStartDate = params[minId] ? new Date(params[minId]) : undefined;
  const initialEndDate = params[maxId] ? new Date(params[maxId]) : undefined;
  const [startDateValue, setStartDateValue] = useState(initialStartDate);
  const [endDateValue, setEndDateValue] = useState(initialEndDate);
  const [error, setError] = useState('');

  const handleStartDateChange = (value: Date) => {
    setError('');
    if (endDateValue && isAfter(value, endDateValue)) {
      setError(t('Start date cannot be after end date'));
      return;
    }
    setParams({ [minId]: format(value, displayFormat) });
    setStartDateValue(value);
  };

  const handleEndDateChange = (value: Date) => {
    setError('');
    if (startDateValue && isBefore(value, startDateValue)) {
      return setError(t('End date cannot be before start date'));
    }
    setParams({ [maxId]: format(value, displayFormat) });
    setEndDateValue(value);
  };

  const resetMinValue = () => {
    removeParam(minId);
    setError('');
    setStartDateValue(undefined);
  };

  const resetMaxValue = () => {
    removeParam(maxId);
    setError('');
    setEndDateValue(undefined);
  };

  return (
    <Flex flexDirection="column" gap="1rem">
      <div className={panelInputs}>
        <FilterDateInput
          id="startDate"
          align="start"
          displayFormat={displayFormat}
          label={minLabel}
          value={startDateValue}
          onClear={resetMinValue}
          onSelectDate={handleStartDateChange}
        />
        <Separator size="16px" />
        <FilterDateInput
          id="endDate"
          align="end"
          displayFormat={displayFormat}
          label={maxLabel}
          value={endDateValue}
          onClear={resetMaxValue}
          onSelectDate={handleEndDateChange}
        />
      </div>
      {error ? <ErrorText>{error}</ErrorText> : null}
    </Flex>
  );
};

DateFilter.displayName = 'DateFilter';
