import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function DragVerticalIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, props.className)}
    >
      <path
        d="M10 4.85833L12.6417 7.5L13.8167 6.325L10 2.5L6.17505 6.325L7.35838 7.5L10 4.85833ZM10 15.1417L7.35838 12.5L6.18338 13.675L10 17.5L13.825 13.675L12.6417 12.5L10 15.1417Z"
        fill="currentColor"
      />
    </svg>
  );
}
