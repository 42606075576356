import { ForwardedRef, forwardRef } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import {
  alignEnd,
  alignStart,
  contentVariants,
  ContentVariants,
  item,
  menuSeparator,
} from './dropdown-menu.css';

/**
 * Dropdown Menu Component
 */

/**
 * Trigger
 */

const Trigger = forwardRef(
  (
    props: DropdownMenu.DropdownMenuTriggerProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <DropdownMenu.Trigger
        ref={ref}
        {...props}
        className={clsx(props.className)}
      />
    );
  }
);
Trigger.displayName = 'DropdownMenuTrigger';

/**
 * Content
 */

export type ContentProps = DropdownMenu.DropdownMenuContentProps &
  ContentVariants;

const Content = forwardRef(
  (props: ContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { variant = 'light' } = props;
    return (
      <DropdownMenu.Content
        ref={ref}
        {...props}
        className={clsx(contentVariants({ variant }), props.className)}
      />
    );
  }
);
Content.displayName = 'DropdownMenuContent';

/**
 * SubContent
 */

type SubContentProps = DropdownMenu.DropdownMenuSubContentProps &
  ContentVariants;

const SubContent = forwardRef(
  (props: SubContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { variant = 'light' } = props;
    return (
      <DropdownMenu.SubContent
        ref={ref}
        {...props}
        className={clsx(contentVariants({ variant }), props.className)}
      />
    );
  }
);
SubContent.displayName = 'DropdownMenuSubContent';

/**
 * Item
 */

type ItemProps = DropdownMenu.DropdownMenuItemProps & {
  align?: 'start' | 'end';
  className?: string;
};

const Item = forwardRef(
  (props: ItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { align, className } = props;
    return (
      <DropdownMenu.Item
        ref={ref}
        {...props}
        className={clsx({
          [item]: true,
          [alignStart]: align === 'start',
          [alignEnd]: align === 'end',
          className,
        })}
      />
    );
  }
);
Item.displayName = 'DropdownMenuItem';

/**
 * CheckboxItem
 */

type CheckboxItemProps = DropdownMenu.DropdownMenuCheckboxItemProps & {
  className?: string;
};

const CheckboxItem = forwardRef(
  (props: CheckboxItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <DropdownMenu.CheckboxItem
        ref={ref}
        {...props}
        className={clsx(item, props.className)}
      />
    );
  }
);
CheckboxItem.displayName = 'DropdownMenuCheckboxItem';

/**
 * RadioItem
 */

type RadioItemProps = DropdownMenu.DropdownMenuRadioItemProps & {
  className?: string;
};

const RadioItem = forwardRef(
  (props: RadioItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <DropdownMenu.RadioItem
        ref={ref}
        {...props}
        className={clsx(item, props.className)}
      />
    );
  }
);
RadioItem.displayName = 'DropdownMenuRadioItem';

/**
 * SubTrigger
 */

type SubTriggerProps = DropdownMenu.DropdownMenuSubTriggerProps & {
  className?: string;
};

const SubTrigger = forwardRef(
  (props: SubTriggerProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <DropdownMenu.SubTrigger
        ref={ref}
        {...props}
        className={clsx(item, props.className)}
      />
    );
  }
);
SubTrigger.displayName = 'DropdownMenuSubTrigger';

const Arrow = DropdownMenu.Arrow;

const Group = DropdownMenu.Group;

const ItemIndicator = DropdownMenu.ItemIndicator;

const Label = DropdownMenu.Label;

const Portal = DropdownMenu.Portal;

const RadioGroup = DropdownMenu.RadioGroup;

const Root = DropdownMenu.Root;

const Separator = (props: DropdownMenu.DropdownMenuSeparatorProps) => (
  <DropdownMenu.Separator className={menuSeparator} {...props} />
);

const Sub = DropdownMenu.Sub;

export {
  Arrow,
  CheckboxItem,
  Content,
  Group,
  Item,
  ItemIndicator,
  Label,
  Portal,
  RadioGroup,
  RadioItem,
  Root,
  Separator,
  Sub,
  SubContent,
  SubTrigger,
  Trigger,
};
