import clsx from 'clsx';

export function Logo({ className }: { className?: string }) {
  return (
    <svg
      width="66"
      height="28"
      viewBox="0 0 66 28"
      fill="none"
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5112 6.3852C6.95214 6.14811 6.22103 5.90759 5.33906 5.67185C4.68622 5.50123 4.16451 5.3402 3.78945 5.19424C3.42918 5.05444 3.12956 4.86325 2.8976 4.62685C2.67623 4.40002 2.56837 4.11564 2.56837 3.75588C2.56837 3.21658 2.74321 2.81777 3.10276 2.53613C3.46796 2.25038 3.94878 2.10579 4.53324 2.10579C5.18114 2.10579 5.70708 2.26271 6.09766 2.57313C6.48753 2.88287 6.6934 3.24332 6.72934 3.67502L6.74063 3.81481H9.46268L9.44929 3.65104C9.35693 2.52242 8.87682 1.62062 8.02305 0.970321C7.17633 0.326181 6.08003 0 4.76308 0C3.8402 0 3.01534 0.154182 2.31033 0.459121C1.59826 0.766801 1.03567 1.21633 0.638741 1.79606C0.239704 2.37509 0.0387757 3.06035 0.0387757 3.83057C0.0387757 4.66659 0.242524 5.34773 0.644382 5.8555C1.04131 6.35711 1.52846 6.73194 2.09178 6.97041C2.63745 7.20134 3.35516 7.43228 4.22725 7.65635C4.90689 7.82767 5.44411 7.98802 5.82412 8.13398C6.18931 8.27308 6.49811 8.47318 6.74204 8.7274C6.97751 8.97341 7.09173 9.28589 7.09173 9.68266C7.09173 10.1986 6.90278 10.6078 6.51503 10.9333C6.12586 11.2601 5.5689 11.426 4.85895 11.426C4.149 11.426 3.64209 11.2581 3.27267 10.9277C2.8976 10.5913 2.69174 10.174 2.6445 9.65113L2.63181 9.51202H0V9.66415C0 10.4364 0.215734 11.1224 0.640856 11.7028C1.06315 12.2805 1.65043 12.7321 2.38717 13.0452C3.11685 13.3556 3.94807 13.5132 4.85825 13.5132C5.82129 13.5132 6.67225 13.3289 7.38642 12.9664C8.10342 12.6019 8.65757 12.1112 9.03403 11.5082C9.41051 10.9045 9.60157 10.2528 9.60157 9.57096C9.60157 8.7233 9.3943 8.03256 8.9861 7.51862C8.58283 7.01153 8.0865 6.62984 7.5112 6.3852Z"
        fill="currentColor"
      />
      <path
        d="M27.2678 5.1894C27.7197 4.94339 28.2182 4.81799 28.7496 4.81799C29.2813 4.81799 29.7826 4.9427 30.2415 5.1894C30.697 5.43403 31.0692 5.80475 31.3463 6.29128C31.6255 6.77987 31.7665 7.38975 31.7665 8.1031C31.7665 8.81645 31.6191 9.42838 31.3279 9.92245C31.0381 10.4152 30.6567 10.7893 30.1942 11.0353C29.729 11.2813 29.2235 11.4067 28.6919 11.4067C27.8494 11.4067 27.1711 11.1279 26.6177 10.5536C26.0685 9.9855 25.7901 9.16045 25.7901 8.1031C25.7901 7.38838 25.9254 6.77781 26.1926 6.28855C26.457 5.8027 26.8194 5.43404 27.2678 5.1894ZM31.5958 3.34333C30.7604 2.89928 29.8156 2.67383 28.7877 2.67383C27.7599 2.67383 26.8144 2.89859 25.9797 3.34333C25.1414 3.78874 24.4716 4.43357 23.988 5.2593C23.5052 6.08365 23.2597 7.04027 23.2597 8.1031C23.2597 9.16594 23.4981 10.1363 23.9675 10.9544C24.4385 11.7747 25.0956 12.4196 25.9198 12.8705C26.7426 13.3213 27.6746 13.5496 28.6919 13.5496C29.7092 13.5496 30.6744 13.3221 31.5289 12.8725C32.3847 12.4209 33.0707 11.774 33.567 10.9483C34.0634 10.1232 34.3151 9.16594 34.3151 8.10379C34.3151 7.04164 34.0704 6.08503 33.5867 5.25998C33.1032 4.43425 32.4334 3.78874 31.5958 3.34333Z"
        fill="currentColor"
      />
      <path
        d="M44.7218 11.1046C44.5709 10.9682 44.4941 10.727 44.4941 10.3891V4.96121H47.0131V2.99727H45.6919L44.4941 4.43083V0.297363H41.9835V10.3891C41.9835 11.4335 42.2648 12.2029 42.8204 12.6765C43.3696 13.1459 44.1635 13.383 45.1793 13.383H47.0561V11.3143H45.5614C45.1582 11.3143 44.8756 11.2437 44.7218 11.1046Z"
        fill="currentColor"
      />
      <path
        d="M50.1737 7.04174C50.2921 6.36881 50.5875 5.82473 51.0529 5.4211C51.5555 4.98391 52.1704 4.76258 52.8795 4.76258C53.6649 4.76258 54.3318 4.98734 54.8607 5.43207C55.3533 5.84596 55.6156 6.37361 55.6601 7.04174H50.1737ZM55.7073 3.32628C54.9092 2.89457 53.99 2.67529 52.974 2.67529C51.9581 2.67529 50.9711 2.90073 50.1554 3.34547C49.3354 3.79089 48.691 4.43365 48.2399 5.25459C47.79 6.07141 47.5623 7.03009 47.5623 8.10388C47.5623 9.17768 47.7971 10.1363 48.2602 10.9546C48.7242 11.7748 49.3749 12.4196 50.1927 12.8718C51.0091 13.3228 51.9454 13.5509 52.9747 13.5509C54.2395 13.5509 55.3161 13.2378 56.1741 12.6204C57.0313 12.0036 57.632 11.2006 57.9592 10.2323L58.0261 10.0336H55.3618L55.3189 10.1178C54.8593 11.0237 54.0915 11.4637 52.974 11.4637C52.2027 11.4637 51.5428 11.2266 51.0147 10.7599C50.5247 10.3268 50.2329 9.75397 50.1455 9.05501H58.1537L58.1749 8.92687C58.2264 8.61028 58.2532 8.25189 58.2532 7.86267C58.2532 6.85124 58.0275 5.9419 57.5841 5.16003C57.1385 4.37747 56.5076 3.76006 55.7073 3.32628Z"
        fill="currentColor"
      />
      <path
        d="M19.8476 8.0617C19.8476 8.73804 19.7023 9.33901 19.4168 9.84679C19.1326 10.3518 18.754 10.7418 18.2902 11.0049C17.8263 11.2687 17.3229 11.4023 16.792 11.4023C16.2611 11.4023 15.7761 11.2721 15.3122 11.0137C14.8483 10.7568 14.4697 10.373 14.1863 9.87351C13.9015 9.37259 13.7569 8.77505 13.7569 8.09802C13.7569 7.42098 13.9015 6.82756 14.1863 6.3328C14.4697 5.8401 14.849 5.4591 15.3122 5.20145C15.7768 4.94311 16.2745 4.81291 16.792 4.81291C17.3094 4.81291 17.8277 4.94105 18.2922 5.19254C18.7555 5.44403 19.1341 5.81886 19.4182 6.30539C19.7023 6.79467 19.8476 7.38603 19.8476 8.0617ZM19.863 3.35057C19.0833 2.89899 18.2104 2.66943 17.2694 2.66943C16.4452 2.66943 15.6944 2.83732 15.0372 3.16967C14.5304 3.42595 14.1009 3.73226 13.7555 4.08311V2.83663H11.264V18.2453H13.7555V12.1355C14.1158 12.4912 14.5522 12.7988 15.0563 13.0538C15.7 13.3792 16.4444 13.5444 17.2694 13.5444C18.212 13.5444 19.0847 13.3087 19.8645 12.8441C20.6442 12.3795 21.2639 11.7223 21.7088 10.8897C22.1515 10.0606 22.3757 9.10809 22.3757 8.06102C22.3757 7.01394 22.1508 6.06966 21.7073 5.25832C21.2625 4.44492 20.6428 3.80284 19.863 3.35057Z"
        fill="currentColor"
      />
      <path
        d="M59.6344 2.84229V13.3829H62.1258V4.96109H65.9767V2.99716H63.292L62.1258 4.39302V2.84229H59.6344Z"
        fill="currentColor"
      />
      <path
        d="M38.1377 4.50003V0.297363H35.6271V10.3884C35.6271 11.4327 35.9084 12.2023 36.464 12.6765C37.0132 13.1452 37.8071 13.383 38.8229 13.383H40.6997V11.3135H39.2051C38.8017 11.3135 38.5191 11.2429 38.3654 11.1031C38.2145 10.9668 38.1377 10.7255 38.1377 10.3878V4.96052H40.7138V2.9959H39.3926L38.137 4.49866L38.1377 4.50003Z"
        fill="currentColor"
      />
      <path
        d="M60.8446 27.9642C59.9272 27.9642 59.0989 27.7621 58.3601 27.3576C57.6331 26.9412 57.0553 26.3583 56.6262 25.6089C56.2092 24.8477 56.0007 23.9615 56.0007 22.9504C56.0007 21.9393 56.2152 21.059 56.6441 20.3096C57.0731 19.5484 57.657 18.9655 58.3959 18.5611C59.1347 18.1447 59.9628 17.9365 60.8805 17.9365C61.7979 17.9365 62.6261 18.1447 63.365 18.5611C64.1157 18.9655 64.6997 19.5484 65.1167 20.3096C65.5457 21.059 65.7602 21.9393 65.7602 22.9504C65.7602 23.9496 65.5457 24.8299 65.1167 25.5911C64.6877 26.3524 64.0978 26.9412 63.3471 27.3576C62.5964 27.7621 61.7623 27.9642 60.8446 27.9642ZM60.8446 26.8758C61.4882 26.8758 62.084 26.7331 62.6321 26.4476C63.1803 26.1502 63.6212 25.7101 63.9549 25.1272C64.3005 24.5325 64.4732 23.8068 64.4732 22.9504C64.4732 22.0939 64.3065 21.3742 63.9727 20.7914C63.6391 20.1966 63.1982 19.7565 62.65 19.4711C62.1019 19.1737 61.506 19.0249 60.8624 19.0249C60.2191 19.0249 59.6232 19.1737 59.0751 19.4711C58.5269 19.7565 58.086 20.1966 57.7523 20.7914C57.4306 21.3742 57.2697 22.0939 57.2697 22.9504C57.2697 23.8068 57.4306 24.5325 57.7523 25.1272C58.086 25.7101 58.521 26.1502 59.0572 26.4476C59.6054 26.7331 60.2012 26.8758 60.8446 26.8758Z"
        fill="currentColor"
      />
      <path
        d="M54.1861 16.997C53.9358 16.997 53.7213 16.9077 53.5426 16.7293C53.3638 16.5509 53.2745 16.3309 53.2745 16.0692C53.2745 15.8075 53.3638 15.5934 53.5426 15.4268C53.7213 15.2484 53.9358 15.1592 54.1861 15.1592C54.4363 15.1592 54.6508 15.2484 54.8295 15.4268C55.0083 15.5934 55.0977 15.8075 55.0977 16.0692C55.0977 16.3309 55.0083 16.5509 54.8295 16.7293C54.6508 16.9077 54.4363 16.997 54.1861 16.997ZM54.8117 18.0791V27.8213H53.5604V18.0791H54.8117Z"
        fill="currentColor"
      />
      <path
        d="M41.999 22.9324C41.999 21.9332 42.2016 21.0589 42.6067 20.3095C43.0119 19.5483 43.566 18.9654 44.2691 18.5609C44.9841 18.1446 45.7885 17.9364 46.6822 17.9364C47.5401 17.9364 48.3147 18.1446 49.0058 18.5609C49.697 18.9772 50.2035 19.5185 50.5252 20.1846V14.6177H51.7766V27.8214H50.5252V25.6445C50.2274 26.3226 49.7387 26.8817 49.0595 27.3218C48.3803 27.75 47.5819 27.9641 46.6643 27.9641C45.7707 27.9641 44.9662 27.756 44.2511 27.3397C43.5482 26.9233 42.9941 26.3344 42.5889 25.5732C42.1956 24.8118 41.999 23.9316 41.999 22.9324ZM50.5252 22.9503C50.5252 22.1652 50.3643 21.4753 50.0427 20.8805C49.7209 20.2857 49.2799 19.8277 48.7199 19.5067C48.1717 19.1855 47.564 19.0249 46.8967 19.0249C46.2055 19.0249 45.5858 19.1795 45.0378 19.4887C44.4896 19.7981 44.0546 20.2501 43.7328 20.8448C43.423 21.4276 43.2681 22.1235 43.2681 22.9324C43.2681 23.7294 43.423 24.4313 43.7328 25.0379C44.0546 25.6327 44.4896 26.0906 45.0378 26.4118C45.5858 26.7211 46.2055 26.8757 46.8967 26.8757C47.564 26.8757 48.1717 26.7151 48.7199 26.3939C49.2799 26.0728 49.7209 25.6148 50.0427 25.0201C50.3643 24.4253 50.5252 23.7354 50.5252 22.9503Z"
        fill="currentColor"
      />
      <path
        d="M40.7869 18.0791V27.8214H39.5356V26.1084C39.2496 26.715 38.8088 27.179 38.213 27.5002C37.6171 27.8214 36.9497 27.9818 36.211 27.9818C35.0431 27.9818 34.0898 27.625 33.351 26.9114C32.6122 26.1857 32.2428 25.1389 32.2428 23.771V18.0791H33.4761V23.6282C33.4761 24.6869 33.7383 25.4958 34.2627 26.0549C34.7989 26.614 35.5257 26.8934 36.4433 26.8934C37.3848 26.8934 38.1356 26.5961 38.6955 26.0014C39.2556 25.4066 39.5356 24.5323 39.5356 23.3784V18.0791H40.7869Z"
        fill="currentColor"
      />
      <path
        d="M28.6599 19.132V25.1807C28.6599 25.7754 28.773 26.1858 28.9995 26.4118C29.2258 26.6378 29.6251 26.7509 30.197 26.7509H31.3409V27.8214H30.0004C29.1186 27.8214 28.4632 27.6192 28.0342 27.2147C27.6052 26.7984 27.3907 26.1204 27.3907 25.1807V19.132H26.0322V18.0792H27.3907V15.6348H28.6599V18.8068L29.3077 18.0792H31.3409V19.132H28.6599Z"
        fill="currentColor"
      />
      <path
        d="M21.6661 27.9463C20.8438 27.9463 20.117 27.8036 19.4853 27.5181C18.8658 27.2325 18.3771 26.8341 18.0196 26.3226C17.6622 25.8111 17.4715 25.2401 17.4477 24.6097H18.7703C18.83 25.2163 19.0981 25.7516 19.5748 26.2156C20.0514 26.6676 20.7486 26.8936 21.6661 26.8936C22.5122 26.8936 23.1795 26.6794 23.6681 26.2512C24.1686 25.8111 24.4188 25.258 24.4188 24.5919C24.4188 24.0566 24.2818 23.6284 24.0077 23.3072C23.7336 22.9741 23.394 22.7302 22.9889 22.5756C22.5836 22.409 22.0235 22.2306 21.3086 22.0403C20.4744 21.8143 19.8131 21.5942 19.3245 21.3801C18.836 21.166 18.4188 20.833 18.0733 20.381C17.7277 19.929 17.555 19.3164 17.555 18.5432C17.555 17.9008 17.7217 17.3298 18.0555 16.8302C18.3891 16.3187 18.8598 15.9202 19.4675 15.6348C20.0753 15.3492 20.7723 15.2065 21.5589 15.2065C22.7147 15.2065 23.6443 15.492 24.3473 16.063C25.0623 16.6221 25.4734 17.3476 25.5807 18.2398H24.2222C24.1388 17.7283 23.8587 17.2763 23.3821 16.8838C22.9054 16.4793 22.2619 16.2771 21.4516 16.2771C20.7009 16.2771 20.0753 16.4793 19.5748 16.8838C19.0743 17.2763 18.824 17.8175 18.824 18.5075C18.824 19.0308 18.9611 19.4531 19.2352 19.7742C19.5092 20.0954 19.8489 20.3393 20.2541 20.5059C20.6711 20.6724 21.2311 20.8508 21.9343 21.0411C22.7445 21.2671 23.4 21.4932 23.9005 21.7191C24.4009 21.9332 24.8239 22.2664 25.1695 22.7184C25.5151 23.1704 25.6879 23.777 25.6879 24.5384C25.6879 25.1212 25.533 25.6743 25.2231 26.1977C24.9133 26.7211 24.4546 27.1434 23.8468 27.4646C23.2391 27.7857 22.5122 27.9463 21.6661 27.9463Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LogoMark({ className }: { className?: string }) {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.529 9.8492L16.4231 11.4793L22.3675 4.28534L20.2956 2.52052L14.3512 9.71449V0.324341H11.6473V9.71449L5.70441 2.52052L3.63252 4.28534L9.57691 11.4793L0.469529 9.8492L0 12.5524L9.10738 14.1825L1.09855 18.8776L2.4505 21.2554L10.4593 16.5603L7.29634 25.3844L9.83776 26.3243L12.9993 17.5003L16.1622 26.3243L18.7037 25.3844L15.5407 16.5603L23.5495 21.2554L24.9015 18.8776L16.8926 14.1825L26 12.5524L25.529 9.8492Z"
        fill="currentColor"
      />
    </svg>
  );
}
