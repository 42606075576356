import { PropsWithChildren } from 'react';
import { Logo } from '@lib/ui';
import * as Styles from './auth-layout.css';
import { AuthFooter } from './footer';

export function AuthLayout(props: PropsWithChildren) {
  return (
    <main className={Styles.layout}>
      <header className={Styles.header}>
        <Logo />
      </header>
      {props.children}
      <AuthFooter />
    </main>
  );
}
