import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  subscription_signup_plan_toggle,
  subscription_signup_sign_in_with_google_click,
} from '@studio/heap/events';
import { trackEvent } from '@studio/lib/heap';
import { Flex, Heading, Label, Text, ToggleGroup } from '@lib/ui';
import { AuthLayout } from '../../components';
import { useFetchStripePlans } from '../../components/pricing-plans/hooks/use-fetch-stripe-plans';
import { useLocalStorage } from '../../components/pricing-plans/hooks/use-local-storage';
import { PricingPlanPlans } from '../../components/pricing-plans/pricing-plans-plans';
import * as Styles from '../../components/pricing-plans/pricing-plans.css';
import { PARTNER_STACK_KEY, PartnerStackUTMParams } from '../../hooks';

export enum PlanType {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

const defaultStripePlanId = import.meta.env.VITE_STRIPE_STUDIO_PLAN_ID;

export function PricingPlans() {
  const { t } = useTranslation();
  const [planType, setPlanType] = useState<PlanType>(PlanType.Monthly);
  const { data: stripePlans, isLoading } = useFetchStripePlans();
  const { getItem } = useLocalStorage();
  const utmParams = getItem<PartnerStackUTMParams>(PARTNER_STACK_KEY);
  const planId = utmParams?.plan;
  const stripeProductId = !planId ? defaultStripePlanId : planId;

  useEffect(() => {
    trackEvent(subscription_signup_sign_in_with_google_click);
  }, []);

  const plan = stripePlans?.find(({ _id }) => _id === stripeProductId);

  if (isLoading && !plan) {
    return (
      <AuthLayout>
        <Flex justifyContent="center" alignItems="center">
          <Heading size="36">{t('Loading...')}</Heading>
        </Flex>
      </AuthLayout>
    );
  }

  if (!plan) {
    return (
      <AuthLayout>
        <Flex justifyContent="center" alignItems="center">
          <Heading size="36">{t('No plans available.')}</Heading>
        </Flex>
      </AuthLayout>
    );
  }

  const planDiscount = plan.discount?.percentage
    ? `${plan.discount.percentage}%`
    : null;

  const handleToggle = (type: string) => {
    type && setPlanType(type as PlanType);
    trackEvent(subscription_signup_plan_toggle);
  };

  return (
    <AuthLayout>
      <Flex
        flexDirection="column"
        alignItems="center"
        className={Styles.container}
      >
        <Text className={Styles.header}>{t('Choose your plan')}</Text>
        <ToggleGroup.Root
          type="single"
          size="sm"
          pill={true}
          variant="subtle"
          value={planType}
          onValueChange={handleToggle}
          className={Styles.toggleGroup}
        >
          <ToggleGroup.Item
            id="monthly"
            value={PlanType.Monthly}
            aria-label="Monthly"
            label="Monthly"
            className={Styles.toggleItem}
          >
            <Text size="14" className={Styles.toggleName}>
              {t('Monthly')}
            </Text>
          </ToggleGroup.Item>
          <ToggleGroup.Item
            id="yearly"
            value={PlanType.Yearly}
            aria-label="Yearly"
            label="Yearly"
            className={Styles.toggleItem}
          >
            <Text size="14" className={Styles.toggleName}>
              {t('Yearly')}
            </Text>
            {planDiscount && (
              <Label className={Styles.discountLabel}>
                {t(`Save ${planDiscount}`)}
              </Label>
            )}
          </ToggleGroup.Item>
        </ToggleGroup.Root>
        <PricingPlanPlans plan={plan} planType={planType} />
      </Flex>
    </AuthLayout>
  );
}
