import { lazy, ReactNode, Suspense } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import useIsMobile from '@studio/hooks/use-is-mobile';
import { BirdoProvider } from '@studio/lib/birdo-client';
import { Sidebar as SidebarMenu } from '@lib/ui';
import { useAuthStore } from '../../auth';
import { appLayout } from './layout.css';
import { ValidChannelMatch } from './valid-channel-match';
import { ValidOrganizationMatch } from './valid-organization-match';

const ExitModal = lazy(
  () => import('@studio/features/projects/components/exit-modal/exit-modal')
);
const SavedItems = lazy(
  () => import('@studio/features/saved-items/saved-items')
);
const Sidebar = lazy(() => import('./sidebar'));
const TopBar = lazy(() => import('./topbar'));

export default function AppLayout({ children }: { children?: ReactNode }) {
  const { user } = useAuthStore();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/auth/logout" />;
  }

  if (isMobile) {
    return <Navigate to="/auth/is-mobile" />;
  }

  return (
    <Suspense>
      <ValidOrganizationMatch>
        <ValidChannelMatch>
          <SidebarMenu.Root navigate={navigate}>
            <div className={appLayout}>
              <TopBar />
              <Sidebar />
              {children ? children : <Outlet />}
              <SavedItems />
              <ExitModal />
            </div>
          </SidebarMenu.Root>
          <BirdoProvider />
        </ValidChannelMatch>
      </ValidOrganizationMatch>
    </Suspense>
  );
}
